// 判断文字是否过长，用于判断是否需要显示tooltip
export const textRange =  function(el) {
    const textContent = el
    // ＋1是防止缩放分辨率时会有一点点的偏差导致结果改变
    const targetW = textContent.getBoundingClientRect().width+1
    const range = document.createRange()
    range.setStart(textContent, 0)
    range.setEnd(textContent, textContent.childNodes.length)
    const rangeWidth = range.getBoundingClientRect().width
    return rangeWidth > targetW
}
