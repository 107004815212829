import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'
// 设为模板
export function set_to_template(email, content, name) {
    const data = {
        email,
        content,
        name
    }
    return request({
        url: `/mindmap/to_template`,
        method: 'post',
        data: data
    })
}


//删除模版
export function remove_templates(email, name) {
    const data = {
        email,
        name
    }
    return request({
        url: `/mindmap/delete_template`,
        method: 'post',
        data: data
    })
}


export function get_templates() {
    return request({
        url: `/${serviceConfig['file-manage-service']}/settings/exportTemplates`,
        method: 'get',
    })
}

export function del_templates(id) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/settings/exportTemplates/${id}`,
        method: 'delete',
    })
}
export function add_templates(data) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/settings/exportTemplates`,
        method: 'post',
        data
    })
}
