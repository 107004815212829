<template>
  <div class="other_settings">
    <div class="water_mask_wrap">
      <div style="padding-top: 10px">
        <span>{{ $t("personal.otherSettings.watermarkFunction") }}</span>
        <el-switch
          v-model="watermarkSwitch"
          active-color="#13ce66"
          style="margin: 0 40px 0 12px"
          @change="switchChange"
        >
        </el-switch>
      </div>
      <div>
        <el-input
          v-model="watermarkValue"
          style="width: 500px"
          :placeholder="$t('personal.otherSettings.PleaseEnter')"
          @change="inputChange"
        ></el-input>
        <div style="font-size: 14px; margin-top: 6px">
          *{{ $t("personal.otherSettings.tips") }}
        </div>
      </div>
    </div>
    <div class="water_mask_wrap">
      <div style="padding-top: 10px">
        <span>{{ $t("personal.otherSettings.share") }}</span>
        <el-switch
          v-model="shareSwitch"
          active-color="#13ce66"
          style="margin: 0 40px 0 12px"
          @change="shareSwitchChange"
        >
        </el-switch>
      </div>
    </div>
    <div class="water_mask_wrap">
      <div style="padding-top: 10px; display: flex; width: 100%">
        <div style="min-width: 150px">
          <span>{{ $t("btn.tem") }}</span>
          <el-tooltip class="item" effect="dark" placement="bottom">
            <div slot="content">
              {{ $t("model_view.tip") }}
            </div>
            <i class="el-icon-question" style="font-size: 20px"></i>
          </el-tooltip>
        </div>
        <div style="width: 80%">
          <button class="endBtn" @click="before_upload">
            <i
              class="el-icon-upload2"
              style="color: rgb(255, 255, 255); font-size: 21px"
            ></i>
            <span class="endText">{{ $t("btn.uploadFile") }}</span>
          </button>
          <!-- <div class="add" >
            <i class="el-icon-upload2"></i> {{ $t("btn.uploadFile") }}
          </div> -->

          <div
            style="
              display: flex;
              margin: 0 0 20px 10px;
              align-items: center;
              width: 100%;
              overflow: auto;
            "
          >
            <div class="con" style="display: flex">
              <!-- <div
                class="model_btn"
                @click="
                  download(
                    config.oss_url +
                      '/7db3b28d-c80f-4fbe-895e-6209c6c4362b/node_accessors/1726737928944Word默认模板.docx'
                  )
                "
              >
                Word {{ $t("model_view.de3f") }} .docx
                <div class="label_type">
                  <img
                    src="@/assets/img/word.png"
                    alt=""
                    style="width: 20px; height: 20px"
                  />
                </div>
              </div> -->

              <div
                v-for="(item, index) in wordObjects"
                :key="item.templateId"
                class="model_btn"
                @click="download(config.oss_url + '/' + item.filePath)"
              >
                <div
                  class="del"
                  @click.stop="del_tem(item)"
                  v-show="item.tenantId"
                >
                  <i class="el-icon-minus"></i>
                </div>
                <el-tooltip
                  :disabled="!isShowToolTip"
                  class="item"
                  effect="dark"
                  :content="item.name"
                  placement="top"
                >
                  <div
                    id="nameElement"
                    class="name"
                    @mouseenter="(event) => ifShowToolTip(event)"
                  >
                    {{ item.name }}
                  </div>
                </el-tooltip>

                <div class="label_type">
                  <img
                    src="@/assets/img/word.png"
                    alt=""
                    style="width: 20px; height: 20px"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style="
              display: flex;
              margin: 0 0 20px 10px;
              align-items: center;
              width: 100%;
              overflow: auto;
            "
          >
            <div class="con" style="display: flex">
              <!-- <div
                class="model_btn"
                @click="
                  download(
                    config.oss_url +
                      '/7db3b28d-c80f-4fbe-895e-6209c6c4362b/node_accessors/1726737932456Excel默认模板.xlsx'
                  )
                "
              >
                Excel{{ $t("model_view.de3f") }}.xlsx
                <div class="label_type">
                  <img
                    src="@/assets/img/excel.png"
                    alt=""
                    style="width: 20px; height: 20px"
                  />
                </div>
              </div> -->
              <div
                v-for="(item, index) in excelObjects"
                :key="item.templateId"
                class="model_btn"
                @click="download(config.oss_url + '/' + item.filePath)"
              >
                <div
                  class="del"
                  @click.stop="del_tem(item)"
                  v-show="item.tenantId"
                >
                  <i class="el-icon-minus"></i>
                </div>
                <el-tooltip
                  :disabled="!isShowToolTip"
                  class="item"
                  effect="dark"
                  :content="item.name"
                  placement="top"
                >
                  <div
                    id="nameElement"
                    class="name"
                    @mouseenter="(event) => ifShowToolTip(event)"
                  >
                    {{ item.name }}
                  </div>
                </el-tooltip>

                <div class="label_type">
                  <img
                    src="@/assets/img/excel.png"
                    alt=""
                    style="width: 20px; height: 20px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showFeatureService" class="feature-service">
      <p style="text-align: left">{{ $t("personal.otherSettings.Custom") }}</p>
      <div
        v-for="(item, index) in serviceList"
        :key="index"
        style="text-align: left; padding: 10px 0; border-bottom: 1px solid #eee"
      >
        <span style="display: inline-block; width: 400px">
          {{ item.service }}
        </span>
        <el-input
          v-model="item.featureService"
          style="width: 500px"
          @change="
            (val) => {
              featureServiceChange(val, item.service);
            }
          "
        ></el-input>
      </div>
    </div>
    <div class="dangerzone_wrap">
      <p>
        {{ $t("personal.otherSettings.dangerzone") }}
        <i class="el-icon-warning-outline" style="color: #f56c6c"></i>
      </p>
      <p>
        <el-button type="danger" @click="showDeregistertenant">
          {{ $t("personal.otherSettings.Deregistertenant") }}</el-button
        >
      </p>
      <p>
        <el-alert
          :title="$t('personal.otherSettings.warningMsg3')"
          type="error"
          show-icon
          :closable="false"
        >
        </el-alert>
      </p>
    </div>
    <el-dialog
      :title="$t('personal.otherSettings.Deregistertenant')"
      :visible.sync="deregistertenantDialog"
      width="30%"
    >
      <p style="text-align: left">
        {{ $t("personal.otherSettings.DeregistertenantDes") }}
      </p>
      <p style="text-align: left">
        <el-input
          v-model="code"
          type="text"
          maxlength="6"
          :placeholder="$t('personal.otherSettings.please')"
          style="width: 60%; height: 40px"
          class="getCode-input"
        />
        <el-button
          icon="iconfont icon-a-youxiang1"
          style="width: 40%; height: 40px; overflow: hidden"
          @click="getCode"
          >{{ $t("personal.otherSettings.getCode") }}</el-button
        >
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            deregistertenantDialog = false;
            code = '';
          "
          >{{ $t("btn.cancelBtn") }}</el-button
        >
        <el-button type="primary" @click="deregistertenantDialogConfirm">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </span>
    </el-dialog>
    <input
      id="up_file"
      type="file"
      multiple
      accept=".docx,.xlsx"
      style="display: none"
      @change="handleFilesChange"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { getWatermark, putWatermark } from "@/network/watermask";
import { deregister } from "@/network/user/index.js";
import { textRange } from "@/utils/textRange";

import config from "@/../config/index";
import { ossAuthorization } from "@/utils/oss";
import {
  get_templates,
  del_templates,
  add_templates,
} from "@/network/template/index.js";
import {
  get_fileShareSetting,
  change_fileShareSetting,
} from "@/network/share/index.js";
import { request_verify_code } from "@/network/login/index.js";
export default {
  name: "OtherSettings",
  data() {
    return {
      watermarkSwitch: false,
      shareSwitch: false,
      watermarkValue: "",
      featureService: "",
      // 注销租户操作
      deregistertenantDialog: false,
      code: "",
      timer: "", //水印设置定时器
      isOverflow: {},
      isShowToolTip: false,
      excelObjects: [],
      up_files: [],
      config: config,
      wordObjects: [],
      serviceList: [
        {
          service: "account-service",
          featureService: localStorage.getItem("account-service"),
        },
        {
          service: "project-service",
          featureService: localStorage.getItem("project-service"),
        },
        {
          service: "file-manage-service",
          featureService: localStorage.getItem("file-manage-service"),
        },
        {
          service: "mindmap-service",
          featureService: localStorage.getItem("mindmap-service"),
        },
        {
          service: "mindmap-service-v2",
          featureService: localStorage.getItem("mindmap-service-v2"),
        },
        {
          service: "application-store-service",
          featureService: localStorage.getItem("application-store-service"),
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["email"]),
    showFeatureService() {
      return (
        process.env.NODE_ENV === "test" ||
        process.env.NODE_ENV === "development"
      );
    },
  },
  mounted() {
    this.get_data();
    get_fileShareSetting().then((res) => {
      this.shareSwitch = res;
    });
    getWatermark().then((res) => {
      this.watermarkSwitch = res.switchFlag ? true : false;
      this.watermarkValue = res.content ? res.content : "";
      this.SET_WATERMASK({
        waterMaskSwitch: this.watermarkSwitch,
        waterMaskValue: this.watermarkValue,
      });
    });
  },
  methods: {
    ...mapMutations(["SET_WATERMASK"]),
    ifShowToolTip(event) {
      this.isShowToolTip =
        event.target.scrollHeight > event.target.clientHeight;
    },
    download(url) {
      window.open(url);
    },
    async handleFilesChange(event) {
      let files = event.target.files;

      function isDocxOrXlsx(fileName) {
        const lowerCaseFileName = fileName.toLowerCase();
        return (
          lowerCaseFileName.endsWith(".docx") ||
          lowerCaseFileName.endsWith(".xlsx")
        );
      }

      let flag = false;
      let f = [];

      for (let i of files) {
        if (!isDocxOrXlsx(i.name)) {
          flag = true;
        } else {
          f.push(i);
        }
      }

      if (flag) {
        this.$message.warning(this.$t("model_view.tip2"));
      }

      flag = false;
      await ossAuthorization.call(this);

      // Create an array to hold promises
      const uploadPromises = f.map(async (i) => {
        const ress = await this.client.put(
          `/${this.$store.state.project.tenantId}/node_accessors/${Date.now()}${
            i.name
          }`,
          i
        );

        let data = {
          name: i.name,
          filePath: ress.name,
          exportTemplateType: i.name.endsWith(".xlsx") ? "EXCEL" : "WORD",
        };

        const res = await add_templates(data);
        if (!res) {
          flag = true; // Mark flag if any upload fails
        }
      });

      // Wait for all uploads to finish
      await Promise.all(uploadPromises);

      if (flag) {
        this.$message.warning(this.$t("model_view.tip3"));
      }

      this.get_data();
    },
    before_upload() {
      document.getElementById("up_file").click();

      // function isDocxOrXlsx(fileName) {
      //   const lowerCaseFileName = fileName.toLowerCase();
      //   if (
      //     lowerCaseFileName.endsWith(".docx") ||
      //     lowerCaseFileName.en,dsWith(".xlsx")
      //   ) {
      //     return true;
      //   }

      //   return false;
      // }
      // if (!isDocxOrXlsx(files.name)) {
      //   this.$message.warning("仅支持word和excel文件");
      //   return false;
      // }
    },
    async upload_attachment_handler(event) {
      await ossAuthorization.call(this);

      this.client
        .put(
          `/${this.$store.state.project.tenantId}/node_accessors/${Date.now()}${
            event.file.name
          }`,
          event.file
        )
        .then((ress) => {
          let data = {
            name: event.file.name,
            filePath: ress.name,
            exportTemplateType: event.file.name.endsWith(".xlsx")
              ? "EXCEL"
              : "WORD",
          };
          add_templates(data).then(() => {
            this.get_data();
          });
        });
    },
    get_data() {
      get_templates().then((res) => {
        this.excelObjects = res.filter(
          (obj) => obj.exportTemplateType === "EXCEL"
        );

        this.wordObjects = res.filter(
          (obj) => obj.exportTemplateType !== "EXCEL"
        );
      });
    },
    del_tem(item) {
      del_templates(item.templateId).then(() => {
        this.$message.success(this.$t("nodeDetail.third.delsucc"));
        this.get_data();
      });
    },
    // 更改服务
    featureServiceChange(val, featureService) {
      localStorage.setItem(featureService, val);
    },
    // 注销租户操作
    showDeregistertenant() {
      this.deregistertenantDialog = true;
    },
    deregistertenantDialogConfirm() {
      if (!this.code) {
        this.$message.warning(this.$t("personal.otherSettings.please"));
        return;
      }
      this.$confirm(
        this.$t("personal.otherSettings.warningMsg1"),
        this.$t("personal.otherSettings.warning"),
        {
          confirmButtonText: this.$t("btn.cancelBtn"),
          cancelButtonClass: "button_danger",
          cancelButtonText: this.$t("personal.otherSettings.deregister"),
          type: "warning",
        }
      )
        .then(() => {
          this.deregistertenantDialog = false;
          this.code = "";
        })
        .catch(() => {
          this.$confirm(
            this.$t("personal.otherSettings.warningMsg2"),
            this.$t("personal.otherSettings.warning"),
            {
              confirmButtonText: this.$t("btn.cancelBtn"),
              cancelButtonClass: "button_danger",
              cancelButtonText: this.$t("personal.otherSettings.deregister"),
              type: "warning",
            }
          )
            .then(() => {
              this.deregistertenantDialog = false;
              this.code = "";
            })
            .catch(() => {
              deregister({
                verifyCode: this.code,
              }).then((res) => {
                this.deregistertenantDialog = false;
                this.code = "";
              });
            });
        });
    },
    getCode() {
      request_verify_code(this.email).then((res) => {
        this.$message.success(res);
      });
    },
    shareSwitchChange() {
      change_fileShareSetting(this.shareSwitch).then((res) => {
        // this.shareSwitch = !this.shareSwitch;
      });
    },
    // 设置水印
    putWatermark() {
      if (this.timer) return;
      this.timer = setTimeout(() => {
        putWatermark({
          switchFlag: this.watermarkSwitch,
          content: this.watermarkValue,
        }).then((res) => {
          this.SET_WATERMASK({
            waterMaskSwitch: this.watermarkSwitch,
            waterMaskValue: this.watermarkValue,
          });
        });
        this.timer = null;
      }, 500);
    },
    inputChange(value) {
      this.putWatermark();
    },
    switchChange(value) {
      this.putWatermark();
    },
  },
};
</script>

<style lang="scss" scoped>
.other_settings {
  height: 80vh;
  overflow: scroll;
  background-color: #fff;
}
.water_mask_wrap,
.dangerzone_wrap {
  border-radius: 4px;
  color: rgba(128, 128, 128, 1);
  font-size: 18px;
  padding: 23px 27px;
  text-align: left;
  display: flex;
}
.feature-service {
  color: rgba(128, 128, 128, 1);
  font-size: 18px;
  padding: 23px 27px;
}
.dangerzone_wrap {
  display: block;
}
.type {
  font-size: 14px;
}
.endBtn {
  display: flex;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  // width: 88px;
  margin-left: 20px;
  height: 32px;
  margin-bottom: 20px;
  font-size: 16px;
  opacity: 1;
  border-radius: 4px;
  background: rgba(40, 78, 163, 1);
  border-style: none;
  cursor: pointer;
}
.add {
  border: 2px solid #284ea3;
  background: #284ea3;
  color: white;
  border-radius: 5px;
  width: 110px;
  margin-left: 20px;
  margin-bottom: 20px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
}
.label_type {
  position: absolute; /* 子元素设置为绝对定位 */
  bottom: 0px; /* 距离顶部0px */
  right: 5px; /* 距离右边0px */
  color: rgb(88, 123, 189);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 12px;
}

.del {
  position: absolute; /* 子元素设置为绝对定位 */
  top: -10px; /* 距离顶部0px */
  right: -15px; /* 距离右边0px */
  border: 2px solid #f56c6c;
  color: #f56c6c;
  border-radius: 50%;
  background-color: white;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
}
.model_btn {
  .name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* 限制显示三行 */
    overflow: hidden; /* 溢出隐藏 */
    text-overflow: ellipsis; /* 超出显示省略号 */
    word-break: break-all; /* 长单词换行 */
    white-space: normal; /* 正常的空白处理 */
    font-size: 14px;
    max-height: 3em; /* 根据字体行高调节 */
    line-height: 1em; /* 行高设置为1.5倍，配合三行效果 */
  }
  position: relative;
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  word-break: break-all;
  margin: 10px 10px;
  height: 80px;
  width: 150px;
  border: 2px solid #dcdfe6;
  border-radius: 16px;
  box-shadow: 0 2px 10px 0 #c6c6c6;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<style lang="scss">
.getCode-input {
  .el-input__inner {
    height: 40px !important;
  }
}
</style>
